<template>
  <div class="container-fluid processLog">
     <LoaderComponent v-if="loading" />
    <div class="container">
      <div class="row justify-content-center flex-column align-content-center align-items-center">
        <h2>Log de Processamento de Matrículas</h2>
        
        <div class="row">
          <div class="row datepicker">
          <date-range-picker
                  v-model="dateRange"
                  @update="getProcessbydata()"
                ></date-range-picker>
              
        </div>
        </div>

        <div class="annotator__infobox" v-if="processamento">
         
          <div class="annotator__infobox--info">
            <h3>Matrículas Processadas</h3>
            <p>{{processamento.matriculasprocessadas}}</p>
          </div>
          <div class="annotator__infobox--info">
            <h3>Matrículas com erros</h3>
            <p>{{processamento.matriculaserror}}</p>
          </div>
          <div class="annotator__infobox--info">
            <h3>Média de Erros</h3>
            <p>{{processamento.mediaerror}}</p>
          </div>




         
        </div>

       <div class="annotator__infobox" v-if="processamento">


          <div class="annotator__infobox--info">
            <h3>Tempo Processando</h3>
            <p>{{processamento.hourProcessTime}}</p>
          </div>
          <div class="annotator__infobox--info">
            <h3>Média de tempo/matrícula</h3>
             <p>{{processamento.mediatime}}</p>
          </div>
         <div class="annotator__infobox--info">
            <h3>Erros de Front</h3>
             <p>{{frontCounterrors}}</p>
          </div>



         
        </div>
        <Listgeneral
          v-if="logs.length > 0"
          :title="`Log de Matrículas`"
          :rows.sync="logs"
          :updated.sync="updated"
          :type="'listlogs'"
          :searchfield="'message'"
          :searchtype="''"
          :numberpages="20"
        />

        <Listgeneral
          v-if="matriculasPerfect.length > 0"
          :title="`Matrículas Processadas`"
          :rows.sync="matriculasPerfect"
          :updated.sync="updated"
          :searchfield="'matricula_id'"
          :searchtype="''"
          :type="'matriculasPerfect'"
          :numberpages="10"
        />
        <Listgeneral
          v-if="matriculasError.length > 0"
          :title="`Matrículas com erros`"
          :rows.sync="matriculasError"
          :updated.sync="updated"
          :searchfield="'stopOn'"
          :searchtype="''"
          :type="'matriculasError'"
          :numberpages="10"
        />

        <Listgeneral
          v-if="countErrors.length > 0"
          :title="`Contagem de erros`"
          :rows.sync="countErrors"
          :updated.sync="updated"
          :searchfield="'Nome do erro'"
          :searchtype="''"
          :type="'errorscount'"
          :numberpages="5"
        />

        <Listgeneral
          v-if="logsFront.length > 0"
          :title="`Erros no Front End`"
          :rows.sync="logsFront"
          :updated.sync="updated"
          :searchfield="'mensagem'"
          :searchtype="''"
          :type="'fronterrors'"
          :numberpages="5"
        />

      </div>

       
    </div>
  </div>
</template>
<script type="text/javascript">
import { Processlogmixin } from "./processlog.mixin";
import Listgeneral from '../../components/ui/list-general/list-general.vue'
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import LoaderComponent from "../../components/ui/loader/loader";
export default {

  components:{Listgeneral,DateRangePicker,LoaderComponent},
  name: "Processlog",
  mixins: [Processlogmixin],
};
</script>
<style src="./processlog.less" lang="less" />
