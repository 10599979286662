import {get } from '../../services/logs.services'
import moment from "moment";
import { formatTime } from "../../helpers/helpers";

export const Processlogmixin = {
    data() {
        return {

            loading: false,
            previousToken: '',
            frontCounterrors: 0,
            nextToken: '',
            logs: [],
            updated: false,
            matriculasNumbers: [],
            matriculasFrontnumbers: [],
            allMatriculasprocess: false,
            processamento: false,
            matriculasError: [],
            matriculasPerfect: [],
            countErrors: [],
            uploadErrors: 0,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            query: {
                logGroupName: 'matriculas',
                logStreamName: 'tools.logger',
                limit: 10000
            },
            queryFront: {
                logGroupName: 'admin_matriculas',
                logStreamName: 'admin_matriculas_error',
                filterPattern: 'from',
                limit: 10000
            },
            logsFront: []

        };
    },
    created() {
        moment.locale('pt-br')
        this.getProcess()

    },
    mounted() {

    },
    filters: {},
    watch: {},
    computed: {

    },

    methods: {
        async getProcess() {
            this.loading = true
            try {
                this.countErrors = []
                this.uploadErrors = 0
                this.logs = []
                this.query.startTime = moment().format('L')
                this.query.endTime = moment().format('L')
                const { data } = await get(this.query)
                this.logs = this.treatData(this.$duplicate(data.data.logs))
                this.logs = this.logs.filter(val => {
                    if (val.message.split('-')[1].trim() != 'post_matricula_upload' && val.message.split('-')[1].trim() != 'post_matricula_process') {
                        return val
                    }
                }).filter(val => { return val.message != undefined })
                this.getMatriculasNumbers(this.$duplicate(this.logs))
                this.generateProcesstime()
                this.updated = true
                this.loading = false
                this.getFrontprocess()

            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
                console.log(e)
            }
        },

        async getFrontprocess() {
            try {
                const { data } = await get(this.queryFront)
                console.log(data)
                this.frontCounterrors = data.data.len
                this.logsFront = this.mountLogsfront(this.$duplicate(data.data.logs))

            } catch (e) {
                this.$tokentest(e.response.status)
            }
        },

        mountLogsfront(data) {
            let logsFront = []
            logsFront = data
            logsFront = logsFront.map(val => {
                    val.message = JSON.parse(val.message)

                    val.nome = val.message.from
                    val.mensagem = val.message.message
                    val.hourerror = moment(new Date(val.timestamp)).format('MMMM Do YYYY, h:mm:ss a')
                    delete val.message
                    delete val.eventId
                    delete val.ingestionTime
                    delete val.logStreamName


                    return val
                }).sort((a, b) => {
                    return b.timestamp - a.timestamp
                })
                .map(val => {
                    delete val.timestamp
                    return val
                })
            return logsFront
        },

        getMatriculasNumbers(data) {
            this.matriculasNumbers = data.map(val => {
                    val.matricula_id = val.message.split('-')
                    val.matricula_id = val.matricula_id[2]
                    return val
                })
                .map(val => {
                    if (val.matricula_id != '  ') {
                        val.matricula_id = val.matricula_id.trim()
                        return val.matricula_id
                    }

                })
                .filter(val => {
                    return val != undefined
                })
                .filter((val, index, self) => {
                    return self.indexOf(val) === index
                })

        },

        generateProcesstime() {
            this.allMatriculasprocess = []

            for (const i in this.matriculasNumbers) {
                let end = false
                let pr_start = 'Reprocess'
                this.allMatriculasprocess.push({
                    'matricula_id': this.matriculasNumbers[i],
                    'process': this.logs.filter(val => {
                        if (this.matriculasNumbers[i] == val.message.split('-')[2].trim()) {
                            return val
                        }
                    })[0],
                    'processStart': this.logs.map(val => {
                        let matricula_id = val.message.split('-')[2].trim()
                        let type = val.message.split('-')[3].trim()
                        if ((this.matriculasNumbers[i] == matricula_id) && (type == 'OCR')) {
                            pr_start = 'OCR'
                            return val.timestamp
                        }
                    }).filter(val => { return val != undefined })[0] || 0,
                    'pr_start': pr_start,
                    'processEnd': this.logs.map(val => {
                        let matricula_id = val.message.split('-')[2].trim()
                        let type = val.message.split('-')[3].trim()
                        if ((this.matriculasNumbers[i] == matricula_id) && (type == 'The end')) {
                            end = true
                            return val.timestamp
                        }
                    }).filter(val => { return val != undefined })[0] || 0,

                    'finished': end

                })

            }


            this.allMatriculasprocess = this.allMatriculasprocess.map(val => {
                if (val.pr_start !== 'OCR') {
                    val.finished = false
                }
                if (val.processEnd != 0 && val.processStart != 0 && val.finished === true) {
                    val.timeProcess = formatTime(Math.round((val.processEnd - val.processStart) / 1000))
                    val.processInt = Math.round((val.processEnd - val.processStart) / 1000)
                    val.stopOn = 'perfect'
                    val['Início'] = moment(new Date(val.processStart)).format('MMMM Do YYYY, h:mm:ss a')
                    val['Fim'] = moment(new Date(val.processEnd)).format('MMMM Do YYYY, h:mm:ss a')
                } else {
                    val['Início'] = moment(new Date(val.processStart)).format('MMMM Do YYYY, h:mm:ss a')
                    val.timeProcess = 'Matrícula Error'
                    val.processInt = 'Matrícula Error'
                    if (val.pr_start == 'OCR') {
                        val.stopOn = val.process.message.split('-')[3].trim()
                    } else {
                        val.stopOn = 'Reprocessamento'
                    }

                }
                return val
            })
            this.matriculasError = this.allMatriculasprocess.filter(val => {
                return val.processInt === 'Matrícula Error'
            })
            this.matriculasPerfect = this.allMatriculasprocess.filter(val => {
                return val.processInt !== 'Matrícula Error'
            }).sort((a, b) => {
                return b.processInt - a.processInt
            })

            this.createProcessamento()
                .then((res) => {
                    this.matriculasPerfect = this.matriculasPerfect.map(val => {
                            delete val.process
                            delete val.processStart
                            delete val.processEnd
                            delete val.stopOn
                            delete val.processInt
                            delete val.finished
                            delete val.pr_start

                            return val
                        })
                        .filter(val => {
                            return val != undefined
                        })
                    this.matriculasError = this.matriculasError.map(val => {
                            delete val.process
                            delete val.processEnd
                            delete val.processStart
                            delete val.timeProcess
                            delete val.processInt
                            delete val.processInt
                            delete val.finished
                            delete val.pr_start
                            return val
                        })
                        .filter(val => {
                            return val != undefined
                        })

                    this.logs = this.logs.map(val => {

                            delete val.finished
                            delete val.pr_start
                            return val
                        })
                        .filter(val => {
                            return val != undefined
                        })

                })

        },

        createProcessamento() {
            return new Promise((resolve) => {
                this.processamento = new Object()
                this.processamento.totalProcessTime = this.matriculasPerfect.map(val => { return val.processInt }).reduce((ac, val) => { return ac + val })
                this.processamento.mediatime = formatTime(this.processamento.totalProcessTime / this.matriculasPerfect.length)
                this.processamento.hourProcessTime = formatTime(this.processamento.totalProcessTime)
                this.processamento.matriculasprocessadas = this.matriculasPerfect.length
                this.processamento.matriculaserror = this.matriculasError.length
                this.processamento.mediaerror = `${Math.round(this.matriculasError.length  / this.matriculasNumbers.length * 100)}%`

                let labelserror = this.matriculasError.map(val => { return val.stopOn }).filter((val, index, self) => { return self.indexOf(val) === index })

                let counterrors = []

                for (let i in labelserror) {
                    counterrors.push({
                        'Nome do erro': labelserror[i],
                        'Contagem': this.matriculasError.filter(val => { return val.stopOn == labelserror[i] }).length,
                        'Percentual': `${(this.matriculasError.filter(val => { return val.stopOn == labelserror[i] }).length / this.matriculasError.length * 100).toFixed(2)}%`
                    })
                }
                this.countErrors = counterrors.sort((a, b) => {
                    return b['Contagem'] - a['Contagem']
                })
                resolve(this.processamento)
            })

        },

        treatData(logs) {
            logs - logs.sort((a, b) => { return b.timestamp - a.timestamp })
            logs = logs.filter(val => {
                for (let i in val) {
                    if (i == 'eventId' || i == 'ingestionTime' || i == 'logStreamName') {
                        delete val[i]
                    }

                }
                val['data'] = moment(new Date(val.timestamp)).format('MMMM Do YYYY, h:mm:ss a');
                return val
            })

            return logs
        },
        async getProcessbydata() {
            this.loading = true
            try {
                this.countErrors = []
                this.uploadErrors = 0
                this.matriculasError = []
                this.matriculasPerfect = []
                this.logs = []
                this.query.startTime = moment(this.dateRange.startDate).format('L')
                this.queryFront.startTime = moment(this.dateRange.startDate).format('L')
                this.query.endTime = moment(this.dateRange.endDate).format('L')
                this.queryFront.endTime = moment(this.dateRange.endDate).format('L')
                const { data } = await get(this.query)
                this.logs = this.treatData(this.$duplicate(data.data.logs))

                this.logs = this.logs.filter(val => {
                    if (val.message.split('-')[1].trim() != 'post_matricula_upload' && val.message.split('-')[1].trim() != 'post_matricula_process') {
                        return val
                    }

                }).filter(val => { return val != undefined && val.message.split('-')[0].trim() != 'main' })

                this.getMatriculasNumbers(this.$duplicate(this.logs))
                this.generateProcesstime()
                this.updated = true
                this.loading = false
                this.getFrontprocess()

            } catch (e) {
                this.loading = false
                this.$tokentest(e.response.status)
            }
        },

    }
};